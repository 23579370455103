import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  loginUserRequest: ["payload"],
  loginUserOauthRequest: ["payload"],
  loginUserOneTapRequest: ["payload"],
  loginUserSuccess: ["payload"],
  loginUserFailure: ["error"],
  setIsSignedIn: ["currentSession"],
  userLogout: null,
  setCreateAnon: null,
});

export const AuthTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isLoggedIn: false,
  error: null,
  createAnonTriggered: false,
};

const loginUserRequest = (state: any) =>
  Object.assign({}, state, { processing: true });

const loginUserOauthRequest = (state: any) =>
  Object.assign({}, state, { processing: true });

const loginUserOneTapRequest = (state: any) =>
  Object.assign({}, state, { processing: true });

const loginUserSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    processing: false,
    isLoggedIn: true,
    currentUser: payload,
  });

const loginUserFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { processing: false, isLoggedIn: false, error });

const setIsSignedIn = (state: any, { currentSession }: any) =>
  Object.assign({}, state, {
    isLoggedIn: currentSession.status,
    currentUser: currentSession.user,
  });

const userLogout = (state: any) =>
  Object.assign({}, state, {
    isLoggedIn: false,
    currentUser: null,
    createAnonTriggered: false,
  });

const setCreateAnon = (state: any) =>
  Object.assign({}, state, { createAnonTriggered: true });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_USER_REQUEST]: loginUserRequest,
  [Types.LOGIN_USER_OAUTH_REQUEST]: loginUserOauthRequest,
  [Types.LOGIN_USER_ONE_TAP_REQUEST]: loginUserOneTapRequest,
  [Types.LOGIN_USER_SUCCESS]: loginUserSuccess,
  [Types.LOGIN_USER_FAILURE]: loginUserFailure,
  [Types.SET_IS_SIGNED_IN]: setIsSignedIn,
  [Types.USER_LOGOUT]: userLogout,
  [Types.SET_CREATE_ANON]: setCreateAnon,
});
