import { put, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import ProductsActions from "../../redux/product.redux";
import { selectUID } from "../selectors";

export function* getProductById(api: any, action: any): Generator<any,any,any> {
  const { product_id } = action;
  const uid: string = yield select(selectUID);
  try {
    const response: any = yield call(api.getProductById, product_id, uid);
    if (response.ok) {
      yield put(ProductsActions.getProductByIdSuccess(response.data));
    } else {
      yield put(ProductsActions.getProductByIdFailure(response.data));
      yield put(push("/"));
    }
  } catch (error) {
    console.error(error);
  }
}