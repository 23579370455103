import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  getCalixaIgRequest: null,
  getCalixaIgSuccess: ["payload"],
  getCalixaIgFailure: ["error"],
});

export const GeneralTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  calixa_ig: [],
  loading: false,
  error: null,
};

const getCalixaIgRequest = (state: any) =>
  Object.assign({}, state, {
    loading: true,
  });

const getCalixaIgSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    calixa_ig: payload.data,
  });

const getCalixaIgFailure = (state: any, { error }: any) =>
  Object.assign({}, state, {
    loading: false,
    error,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CALIXA_IG_REQUEST]: getCalixaIgRequest,
  [Types.GET_CALIXA_IG_SUCCESS]: getCalixaIgSuccess,
  [Types.GET_CALIXA_IG_FAILURE]: getCalixaIgFailure,
});
