import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { reducer as authReducer } from "./auth.redux";
import { reducer as blogReducer } from "./blog.redux";
import { reducer as canvaReducer } from "./canva.redux";
import { reducer as discoveryReducer } from "./discovery.redux";
import { reducer as formReducer } from "redux-form";
import { reducer as generalReducer } from "./general.redux";
import { reducer as hubReducer } from "./hub.redux";
import { reducer as merchantReducer } from "./merchant.redux";
import { reducer as posReducer } from "./pos.redux";
import { reducer as productReducer } from "./product.redux";
import { reducer as searchReducer } from "./search.redux";
import { reducer as shopifyReducer } from "./shopify.redux";
import { reducer as topPicksReducer } from "./top.picks.redux";
import { reducer as unsplashReducer } from "./unsplash.redux";
import { reducer as usersReducer } from "./users.redux";
import { reducer as windowReducer } from "./window.redux";

const userConfig = {
  key: "users",
  storage,
  blacklist: ["error", "currentUser"],
};

const searchConfig = {
  key: "search",
  storage,
  blacklist: ["*"],
};

const blogConfig = {
  key: "blog",
  storage,
  blacklist: ["*"],
};

const createRootReducer = (history: any) => {
  return combineReducers({
    auth: authReducer,
    blog: persistReducer(blogConfig, blogReducer),
    canva: canvaReducer,
    form: formReducer,
    general: generalReducer,
    hubs: hubReducer,
    merchants: merchantReducer,
    products: productReducer,
    pos: posReducer,
    shopify: shopifyReducer,
    topPicks: topPicksReducer,
    users: persistReducer(userConfig, usersReducer),
    router: connectRouter(history),
    search: persistReducer(searchConfig, searchReducer),
    unsplash: unsplashReducer,
    window: windowReducer,
    discovery: discoveryReducer,
  });
};

export default createRootReducer;
