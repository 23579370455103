import { create } from "apisauce";
import qs from "query-string";

const api = create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'multipart/form-data',
    // Accept: 'multipart/form-data'
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "none",
    }),
});

export default api;
