import { createReducer, createActions } from "reduxsauce";
// import _ from "lodash";

const { Types, Creators }: any = createActions({
  setBlogPosts: ["posts"],
  setBlogCategories: ["categories"],
  setBlogAuthors: ["authors"],
  setCurrentPostId: ["id"],
  setCurrentPostContent: ["currentPost"],
  clearCurrentPostContent: null,
});

export const BlogTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  currentPostId: "",
  lastFetch: null,
  data: [],
  currentPost: [],
  categories: [],
  authors: [],
  error: null,
};

const setBlogPosts = (state: any, { posts }: any) =>
  Object.assign({}, state, { lastFetch: new Date(), data: posts });

const setBlogCategories = (state: any, { categories }: any) =>
  Object.assign({}, state, { lastFetch: new Date(), categories });

const setBlogAuthors = (state: any, { authors }: any) =>
  Object.assign({}, state, { lastFetch: new Date(), authors });

const setCurrentPostId = (state: any, { id }: any) =>
  Object.assign({}, state, { currentPostId: id });

const setCurrentPostContent = (state: any, { currentPost }: any) => {
  console.log(currentPost);
  return Object.assign({}, state, { currentPost });
};

const clearCurrentPostContent = (state: any) =>
  Object.assign({}, state, { currentPostId: "", currentPost: [] });
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_BLOG_POSTS]: setBlogPosts,
  [Types.SET_BLOG_CATEGORIES]: setBlogCategories,
  [Types.SET_BLOG_AUTHORS]: setBlogAuthors,
  [Types.SET_CURRENT_POST_ID]: setCurrentPostId,
  [Types.SET_CURRENT_POST_CONTENT]: setCurrentPostContent,
  [Types.CLEAR_CURRENT_POST_CONTENT]: clearCurrentPostContent,
});
