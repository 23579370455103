import { put, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import AuthActions from "../../redux/auth.redux";
import UserActions from "../../redux/users.redux";
import { selectUserLoginForm } from "../selectors";
import Toaster from "toasted-notes";

export function* localLogin(api: any, loginForm: any): any {
  try {
    const userLoginForm = yield select(selectUserLoginForm);
    const response = yield call(
      api.localLogin,
      userLoginForm || loginForm.payload
    );
    if (response.ok) {
      yield put(AuthActions.loginUserSuccess(response.data));
      yield put(UserActions.setUserAfterAuth(response.data));
      yield put(UserActions.getUserByIdRequest(response.data?.uid));
      yield put(push("/"));
    } else {
      yield put(AuthActions.loginUserFailure(response.data));
      Toaster.notify(`${response.data?.message}`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* oauthLogin(api: any, action: any): any {
  try {
    const response = yield call(api.oauthLogin, action.payload);
    if (response.ok) {
      yield put(AuthActions.loginUserSuccess(response.data));
      yield put(UserActions.setUserAfterAuth(response.data));
      yield put(UserActions.getUserByIdRequest(response.data?.uid));
      yield put(push("/"));
    } else {
      yield put(AuthActions.loginUserFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* oneTapLogin(api: any, action: any): any {
  try {
    const response = yield call(api.oneTapLogin, action.payload);
    if (response.ok) {
      yield put(AuthActions.loginUserSuccess(response.data));
      yield put(UserActions.setUserAfterAuth(response.data));
      yield put(UserActions.getUserByIdRequest(response.data?.uid));
      yield put(push("/"));
    } else {
      yield put(AuthActions.loginUserFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
