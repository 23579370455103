import { takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import { UserTypes as Types } from "../../redux/hub.redux";
import * as Sagas from "./hub-sagas";

export default [
  takeLatest(Types.UPDATE_HUB_REQUEST, Sagas.updateHub, api),
  takeLatest(Types.GET_HUBS_REQUEST, Sagas.getHubs, api),
  takeLatest(Types.GET_HUB_BY_ID_REQUEST, Sagas.getHubById, api),
  takeLatest(Types.GET_MERCHANTS_BY_HUB_REQUEST, Sagas.getMerchantsByHub, api),
  takeLatest(Types.GET_IG_LATEST_REQUEST, Sagas.getIgByHub, api),
];
