import { put, call } from "redux-saga/effects";
import UnsplashActions from "../../redux/unsplash.redux";

export function* searchUnsplash(api: any, action: any): any {
  const { searchTerm } = action;
  const query = JSON.stringify({ searchTerm });
  try {
    const response = yield call(api.searchUnsplash, query);
    if (response.ok) {
      yield put(UnsplashActions.searchUnsplashSuccess(response.data));
    } else {
      yield put(UnsplashActions.searchUnsplashFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
