import { put, call } from "redux-saga/effects";
import TopPicksActions from "../../redux/top.picks.redux";

export function* getTopPicks(api: any, action: any): any {
  try {
    const response = yield call(api.getTopPicks);
    if (response.ok) {
      yield put(TopPicksActions.getTopPicksSuccess(response.data));
    } else {
      yield put(TopPicksActions.getTopPicksFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
