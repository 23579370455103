import api from "./api";

export const createUser = (payload: any) => api.post("users", payload);
export const createAnon = () => api.post("users/anon");
export const createUserOauth = (payload: any) =>
  api.post("users/oauth", payload);
export const updateUser = (id: any, payload: any) =>
  api.put(`users/${id}`, payload);
export const findOrCreateUser = (currentUser: any) =>
  api.post("users", currentUser);
// export const findOrCreateUser = ({ userData, strategy }: any) =>
//   api.post(`users/findOrCreate/${strategy}`, userData)
export const updateUserInfo = (payload: any) => api.put(`users/info`, payload);
export const getUserById = (uid: string) => api.get(`users/${uid}`);
export const changePwd = (payload: any) =>
  api.post(`users/change-password`, payload);

export const getProdRecosById = (uid: string) =>
  api.get(`recosys/products/${uid}`);
