import React, { lazy, Suspense } from "react";
import "babel-polyfill";

import { Switch, Route, Redirect } from "react-router";
// import AsyncComponent from "./hocs/async.component";

import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor } from "./";

import "./App.css";

import LoadingPage from "./pages/loading";

/* --- ASYNC LOADING --- */
const HomePage = lazy(() => import("./pages/home"));
// const LinksPage = lazy(() => import("./pages/home-links"));
const AboutPage = lazy(() => import("./pages/home-about"));
const TeamPage = lazy(() => import("./pages/home-team"));
const NewsroomPage = lazy(() => import("./pages/home-newsroom"));
const LegalPage = lazy(() => import("./pages/legal"));
const DiscoverPage = lazy(() => import("./pages/discovery"));
const BrandsPage = lazy(() => import("./pages/brands"));
const ProductsPage = lazy(() => import("./pages/products"));
const DistrictPage = lazy(() => import("./pages/district-profile"));
const BusinessPage = lazy(() => import("./pages/business-profile"));
const LoginPage = lazy(() => import("./pages/login"));
const UserPage = lazy(() => import("./pages/user-profile"));
const CreateBrandProfilePage = lazy(
  () => import("./pages/create-brand-profile")
);

const ProductDetailPage = lazy(() => import("./pages/product-detail"));
const BlogPage = lazy(() => import("./pages/blog"));

function App() {
  const browserLang = window.navigator.language;
  const fallbackLang = browserLang.includes("fr") ? "fr" : "en";
  const currentLang = window.localStorage.getItem("rcml-lang") || fallbackLang;
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {/* <Route exact path="/links" component={LinksPage} />
          <Route exact path="/fr/hyperliens" component={LinksPage} /> */}
          <Route exact path="/our-story" component={AboutPage} />
          <Route exact path="/fr/a-propos" component={AboutPage} />
          <Route exact path="/team" component={TeamPage} />
          <Route exact path="/fr/equipe" component={TeamPage} />
          <Route exact path="/newsroom" component={NewsroomPage} />
          <Route exact path="/fr/salle-de-presse" component={NewsroomPage} />
          <Route exact path="/discover" component={DiscoverPage} />
          <Route exact path="/fr/inspirations" component={DiscoverPage} />
          <Route exact path="/brands" component={BrandsPage} />
          <Route exact path="/fr/commerces" component={BrandsPage} />
          <Route exact path="/products" component={ProductsPage} />
          <Route exact path="/fr/produits" component={ProductsPage} />
          <Route exact path="/legal/privacy" component={LegalPage} />
          <Route exact path="/blog/:id/:slug" component={BlogPage} />
          <Route exact path="/fr/blog/:id/:slug" component={BlogPage} />
          <Route
            exact
            path="/fr/legal/politique-vie-privee"
            component={LegalPage}
          />
          <Route exact path="/legal/terms-of-use" component={LegalPage} />
          <Route
            exact
            path="/fr/legal/conditions-d-utilisation"
            component={LegalPage}
          />
          <Route exact={true} path="/login" component={LoginPage} />
          <Route exact={true} path="/fr/connexion" component={LoginPage} />
          <Route exact={true} path="/hub/:hub_id" component={DistrictPage} />
          <Route path="/user/:uid" component={UserPage} />
          <Route
            exact={true}
            path="/brand-partner"
            component={CreateBrandProfilePage}
          />
          <Route exact={true} path="/join" component={CreateBrandProfilePage} />
          <Route
            exact={true}
            path="/fr/marque-partenaire"
            component={CreateBrandProfilePage}
          />
          <Route
            exact={true}
            path="/bn/:merchant_id"
            component={BusinessPage}
          />
          <Route
            exact={true}
            path="/pr/:product_id"
            component={ProductDetailPage}
          />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/fr" component={HomePage} />
          <Route path="/">
            <Redirect to={`/${currentLang === "en" ? "" : currentLang}`} />
          </Route>
        </Switch>
      </Suspense>
    </PersistGate>
  );
}

export default App;
