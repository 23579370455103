import React, { createContext, useState } from "react";
import { languageOptions, dictionaryList } from "./options";

interface Dictionary {
  en: string;
  fr: string;
}

const appLang: keyof Dictionary = window.localStorage.getItem(
  "rcml-lang"
) as keyof Dictionary;
const browserLang = window.navigator.language;
const fallbackLang = browserLang.includes("fr") ? "fr" : "en";
const effectiveLang: keyof Dictionary = appLang ? appLang : fallbackLang;

export const LanguageContext = createContext<{
  userLanguage: string;
  dictionary: any;
  userLanguageChange?: any;
}>({
  userLanguage: effectiveLang,
  dictionary: dictionaryList[effectiveLang],
});

// it provides the language context to app
export function LanguageProvider({ children }: any) {
  const [userLanguage, setUserLanguage] = useState(effectiveLang);

  const provider = {
    userLanguage,
    dictionary: (dictionaryList as any)[userLanguage],
    userLanguageChange: (selected: keyof Dictionary) => {
      const newLanguage: keyof Dictionary = Object.keys(
        languageOptions
      ).includes(selected)
        ? selected
        : effectiveLang;
      setUserLanguage(newLanguage);
      window.localStorage.setItem("rcml-lang", newLanguage);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}
