import { createReducer, createActions } from "reduxsauce";
import _ from "lodash";
import { TransformMerchantForSearch } from "../utils/merchant.transforms";

const { Types, Creators }: any = createActions({
  getMerchantsRequest: ["query"],
  getMerchantsSuccess: ["payload"],
  getMerchantsFailure: ["error"],
  getMerchantsByCatRequest: ["cat_id"],
  getMerchantsByCatSuccess: ["payload"],
  getMerchantsByCatFailure: ["error"],
  getMerchantsByAttrRequest: ["brand_attr"],
  getMerchantsByAttrSuccess: ["payload"],
  getMerchantsByAttrFailure: ["error"],
  getMerchantByIdRequest: ["merchant_id"],
  getMerchantByIdSuccess: ["payload"],
  getMerchantByIdFailure: ["error"],
  putMerchantRequest: ["payload", "imgType"],
  putMerchantSuccess: ["payload"],
  putMerchantFailure: ["error"],
  createMerchantRequest: ["payload"],
  createMerchantSuccess: ["payload"],
  createMerchantFailure: ["error"],
  getMerchantIgLatestRequest: ["merchant_id"],
  getMerchantIgLatestSuccess: null,
  getMerchantIgLatestFailure: ["error"],
  setLoadingCoverImg: ["val"],
  setLoadingProfileImg: ["val"],
  setLoadingDesignerImg: ["val"],
  setEditStatus: ["val"],
  resetMerchants: null,
  getMerchantsRecoRequest: null,
  getMerchantsRecoSuccess: ["payload"],
  getMerchantsRecoFailure: ["error"],
  getMerchantsRecentlyViewRequest: null,
  getMerchantsRecentlyViewSuccess: ["payload"],
  getMerchantsRecentlyViewFailure: ["error"],
  getMerchantsMoreRequest: ["payload"],
  getMerchantsMoreSuccess: ["payload"],
  getMerchantsMoreFailure: ["error"],
  updateMerchantLikeRequest: ["payload"],
  updateMerchantLikeSuccess: ["payload"],
  updateMerchantLikeFailure: ["error"],
  updateProductLikeSuccess: ["payload"],
  getMerchantsSearchRequest: ["keyword"],
  getMerchantsSearchSuccess: ["payload"],
  getMerchantsSearchFailure: ["error"],
  setCreateMerchantCurrentStepIdx: ["idx"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  lastFetch: null,
  loading: false,
  data: [],
  error: null,
  loadingCoverImg: false,
  loadingProfileImg: false,
  loadingIgBox: false,
  createMerchantData: null,
  edit: false,
  updating: false,
  reco: [],
  loadingShowMore: false,
  showMoreOffset: 0,
  showMoreDone: false,
  searchResult: [],
  searchLoading: false,
  createMerchantCurrentStepIdx: 0,
  // Spotlights - Get by cat_id and by brand_attr,
  loadingSpotlightsByCat: false,
  spotlightsByCat: {},
  loadingSpotlightsByAttr: false,
  spotlightsByAttr: {},
};

const getMerchantsRequest = (state: any) =>
  Object.assign({}, state, {
    loading: true,
    showMoreOffset: 0,
  });

const getMerchantsSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    lastFetch: new Date(),
    data: _.shuffle(TransformMerchantForSearch(payload.data)),
    metadata: payload.metadata,
    showMoreOffset: payload.offset,
    showMoreDone: payload.showMoreDone,
  });
const getMerchantsFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getMerchantByIdRequest = (state: any) =>
  Object.assign({}, state, { loading: true });

const getMerchantByIdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    currentMerchant: { ...payload, products: _.shuffle(payload.products) },
  });

const getMerchantByIdFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const putMerchantRequest = (state: any) =>
  Object.assign({}, state, { updating: true });
const putMerchantSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    currentMerchant: { ...state.currentMerchant, ...payload },
    edit: false,
    updating: false,
  });
const putMerchantFailure = (state: any, { error }: any) =>
  Object.assign({}, state, {
    loading: false,
    error,
    loadingCoverImg: false,
    loadingProfileImg: false,
    loadingDesignerImg: false,
    edit: false,
    updating: false,
  });

const createMerchantRequest = (state: any) =>
  Object.assign({}, state, { loading: true });

const createMerchantSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, createMerchantData: payload });

const createMerchantFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getMerchantIgLatestRequest = (state: any) =>
  Object.assign({}, state, { ...state, loadingIgBox: true });
const getMerchantIgLatestSuccess = (state: any) =>
  Object.assign({}, state, {
    loadingIgBox: false,
  });
const getMerchantIgLatestFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loadingIgBox: false, error });

const setLoadingCoverImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingCoverImg: val });
const setLoadingProfileImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingProfileImg: val });
const setLoadingDesignerImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingDesignerImg: val });

const setEditStatus = (state: any, { val }: any) =>
  Object.assign({}, state, { edit: val });

const resetMerchants = () => Object.assign({}, INITIAL_STATE);

const getMerchantsRecoRequest = (state: any) =>
  Object.assign({}, state, { loadingReco: true });

const getMerchantsRecoSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { reco: payload, loadingReco: false });

const getMerchantsRecoFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { reco: [], loadingReco: false });

const getMerchantsRecentlyViewRequest = (state: any) =>
  Object.assign({}, state, { loadingRV: true });

const getMerchantsRecentlyViewSuccess = (state: any, { payload }: any) => {
  return Object.assign({}, state, { recentlyView: payload, loadingRV: false });
};

const getMerchantsRecentlyViewFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { reco: [], loadingRV: false });

const updateMerchantLikeRequest = (state: any, { payload }: any) =>
  Object.assign({}, state);

const updateMerchantLikeSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    currentMerchant: {
      ...state.currentMerchant,
      like: payload ? true : false,
    },
  });

const updateMerchantLikeFailure = (state: any, { error }: any) =>
  Object.assign({}, state);

const updateProductLikeSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    currentMerchant: {
      ...state.currentMerchant,
      products: state.currentMerchant.products.map((v: any) => {
        if (v.id === payload.id) return { ...v, like: payload.likeState };
        return v;
      }),
    },
  });

const getMerchantsMoreRequest = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loadingShowMore: true,
  });

const getMerchantsMoreSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loadingShowMore: false,
    data: state.data.concat(
      _.shuffle(TransformMerchantForSearch(payload.data))
    ),
    showMoreOffset: payload.offset,
    showMoreDone: payload.showMoreDone,
  });

const getMerchantsMoreFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loadingShowMore: false, error });

const getMerchantsSearchRequest = (state: any) =>
  Object.assign({}, state, { searchLoading: true });

const getMerchantsSearchSuccess = (state: any, { payload }: any) => {
  return Object.assign({}, state, {
    searchResult: payload,
    searchLoading: false,
  });
};

const getMerchantsSearchFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { searchResult: [], searchLoading: false });

const setCreateMerchantCurrentStepIdx = (state: any, { idx }: any) =>
  Object.assign({}, state, { createMerchantCurrentStepIdx: idx });

const getMerchantsByCatRequest = (state: any) =>
  Object.assign({}, state, {
    loadingSpotlightsByCat: true,
  });
const getMerchantsByCatSuccess = (state: any, { payload }: any) => {
  return Object.assign({}, state, {
    loadingSpotlightsByCat: false,
    spotlightsByCat: {
      ...state.spotlightsByCat,
      [payload?.metadata.cat_id]: payload?.data,
    },
  });
};
const getMerchantsByCatFailure = (state: any) =>
  Object.assign({}, state, {
    loadingSpotlightsByCat: false,
  });

const getMerchantsByAttrRequest = (state: any) =>
  Object.assign({}, state, {
    loadingSpotlightsByAttr: true,
  });
const getMerchantsByAttrSuccess = (state: any, { payload }: any) => {
  return Object.assign({}, state, {
    loadingSpotlightsByAttr: false,
    spotlightsByAttr: {
      ...state.spotlightsByAttr,
      [payload?.metadata.brand_attr]: payload?.data,
    },
  });
};
const getMerchantsByAttrFailure = (state: any, { error }: any) =>
  Object.assign({}, state, {
    error,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MERCHANTS_REQUEST]: getMerchantsRequest,
  [Types.GET_MERCHANTS_SUCCESS]: getMerchantsSuccess,
  [Types.GET_MERCHANTS_FAILURE]: getMerchantsFailure,
  [Types.GET_MERCHANT_BY_ID_REQUEST]: getMerchantByIdRequest,
  [Types.GET_MERCHANT_BY_ID_SUCCESS]: getMerchantByIdSuccess,
  [Types.GET_MERCHANT_BY_ID_FAILURE]: getMerchantByIdFailure,
  [Types.PUT_MERCHANT_REQUEST]: putMerchantRequest,
  [Types.PUT_MERCHANT_SUCCESS]: putMerchantSuccess,
  [Types.PUT_MERCHANT_FAILURE]: putMerchantFailure,
  [Types.CREATE_MERCHANT_REQUEST]: createMerchantRequest,
  [Types.CREATE_MERCHANT_SUCCESS]: createMerchantSuccess,
  [Types.CREATE_MERCHANT_FAILURE]: createMerchantFailure,
  [Types.GET_MERCHANT_IG_LATEST_REQUEST]: getMerchantIgLatestRequest,
  [Types.GET_MERCHANT_IG_LATEST_SUCCESS]: getMerchantIgLatestSuccess,
  [Types.GET_MERCHANT_IG_LATEST_FAILURE]: getMerchantIgLatestFailure,
  [Types.SET_LOADING_COVER_IMG]: setLoadingCoverImg,
  [Types.SET_LOADING_PROFILE_IMG]: setLoadingProfileImg,
  [Types.SET_LOADING_DESIGNER_IMG]: setLoadingDesignerImg,
  [Types.SET_EDIT_STATUS]: setEditStatus,
  [Types.RESET_MERCHANTS]: resetMerchants,
  [Types.GET_MERCHANTS_RECO_REQUEST]: getMerchantsRecoRequest,
  [Types.GET_MERCHANTS_RECO_SUCCESS]: getMerchantsRecoSuccess,
  [Types.GET_MERCHANTS_RECO_FAILURE]: getMerchantsRecoFailure,
  [Types.GET_MERCHANTS_RECENTLY_VIEW_REQUEST]: getMerchantsRecentlyViewRequest,
  [Types.GET_MERCHANTS_RECENTLY_VIEW_SUCCESS]: getMerchantsRecentlyViewSuccess,
  [Types.GET_MERCHANTS_RECENTLY_VIEW_FAILURE]: getMerchantsRecentlyViewFailure,
  [Types.GET_MERCHANTS_MORE_REQUEST]: getMerchantsMoreRequest,
  [Types.GET_MERCHANTS_MORE_SUCCESS]: getMerchantsMoreSuccess,
  [Types.GET_MERCHANTS_MORE_FAILURE]: getMerchantsMoreFailure,
  [Types.UPDATE_MERCHANT_LIKE_REQUEST]: updateMerchantLikeRequest,
  [Types.UPDATE_MERCHANT_LIKE_SUCCESS]: updateMerchantLikeSuccess,
  [Types.UPDATE_MERCHANT_LIKE_FAILURE]: updateMerchantLikeFailure,
  [Types.UPDATE_PRODUCT_LIKE_SUCCESS]: updateProductLikeSuccess,
  [Types.GET_MERCHANTS_SEARCH_REQUEST]: getMerchantsSearchRequest,
  [Types.GET_MERCHANTS_SEARCH_SUCCESS]: getMerchantsSearchSuccess,
  [Types.GET_MERCHANTS_SEARCH_FAILURE]: getMerchantsSearchFailure,
  [Types.SET_CREATE_MERCHANT_CURRENT_STEP_IDX]: setCreateMerchantCurrentStepIdx,
  [Types.GET_MERCHANTS_BY_CAT_REQUEST]: getMerchantsByCatRequest,
  [Types.GET_MERCHANTS_BY_CAT_SUCCESS]: getMerchantsByCatSuccess,
  [Types.GET_MERCHANTS_BY_CAT_FAILURE]: getMerchantsByCatFailure,
  [Types.GET_MERCHANTS_BY_ATTR_REQUEST]: getMerchantsByAttrRequest,
  [Types.GET_MERCHANTS_BY_ATTR_SUCCESS]: getMerchantsByAttrSuccess,
  [Types.GET_MERCHANTS_BY_ATTR_FAILURE]: getMerchantsByAttrFailure,
});
