import { call, select } from "redux-saga/effects";

import { selectUID } from "../selectors";

export function* recordEvent(api: any, action: any): any {
  const { event } = action;
  try {
    const uid = yield select(selectUID);
    yield call(api.recordEvent, { ...event, uid });
  } catch (error) {
    console.error(error);
  }
}
