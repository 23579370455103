import api from "./api";

export const localLogin = (payload: any) => api.post("auth/login", payload);
export const oauthLogin = (payload: any) =>
  api.post("auth/login/oauth", payload);
export const oneTapLogin = (payload: any) =>
  api.post("auth/login/onetap", payload);
export const localLogout = () => api.post("auth/logout");
// export const googleLogin = () => api.get('auth/google')
// export const facebookLogin = () => api.get('auth/facebook')
