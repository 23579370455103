import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  getProductByIdRequest: ["product_id"],
  getProductByIdSuccess: ["payload"],
  getProductByIdFailure: ["error"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
};

const getProductByIdRequest = (state: any) =>
  Object.assign({}, state, { loading: true, data: [] });

const getProductByIdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    data: payload,
  });

const getProductByIdFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_BY_ID_REQUEST]: getProductByIdRequest,
  [Types.GET_PRODUCT_BY_ID_SUCCESS]: getProductByIdSuccess,
  [Types.GET_PRODUCT_BY_ID_FAILURE]: getProductByIdFailure,
});
