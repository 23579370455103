import api from "./api";

export const getHubs = () => api.get("hubs"); // mainpage
export const getMerchantsByHub = (hub_id: string) =>
  api.get(`merchants/hub/${hub_id}`); // hub page
export const getHubById = (hub_id: string, uid: string) =>
  api.get(`hubs/${hub_id}`, { uid });
export const updateHub = (payload: any) => api.put(`hubs`, payload);
export const getIgByHubLatest = (payload: any) =>
  api.get(`hubs/instagram/latest`, payload);
