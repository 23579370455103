import { put, call } from "redux-saga/effects";
import DiscoveryActions from "../../redux/discovery.redux";

export function* getDiscovery(api: any, action: any): any {
  try {
    const response = yield call(api.getDiscovery, {
      timestamp: action.payload,
    });
    if (response.ok) {
      yield put(DiscoveryActions.getDiscoverySuccess(response.data));
    } else {
      yield put(DiscoveryActions.getDiscoveryFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getDiscoveryVisualSearch(api: any, action: any): any {
  try {
    const response = yield call(api.getVisualProductSearch, action.payload);
    if (response.ok) {
      yield put(
        DiscoveryActions.getDiscoveryVisualSearchSuccess(response.data),
      );
    } else {
      yield put(
        DiscoveryActions.getDiscoveryVisualSearchFailure(response.data),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteDiscoveryData(api: any, action: any) {
  try {
    yield call(api.deleteDiscoveryData, {
      id: action.id,
    });
  } catch (error) {
    console.error(error);
  }
}
