import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  setQuery: ["query"],
  clearQuery: null,
  setBrandAttr: ["brand_attr"],
  setCatId: ["cat_id"],
  setProvince: ["province"],
  setCity: ["city"],
  setCountry: ["country"],
  resetSearch: null,
  setRefinementAttribute: ["attribute", "value"],
  setKey: null,
  setSearchState: ["newState"],
});

export const SearchTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  page: 1,
  query: null,
  refinementList: {
    brand_attr: [],
    cat_id: [],
    province: [],
    type: [],
    country: [],
    vendor: [],
  },
};

const setQuery = (state: any, { query }: any) =>
  Object.assign({}, state, { query });

const clearQuery = (state: any) => Object.assign({}, state, { query: null });

const setBrandAttr = (state: any, { brand_attr }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, brand_attr },
  });
};

const setCatId = (state: any, { cat_id }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, cat_id },
  });
};

const setProvince = (state: any, { province }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, province },
  });
};

const setCity = (state: any, { city }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, city },
  });
};

const setCountry = (state: any, { country }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, country },
  });
};

const resetSearch = (state: any) => Object.assign({}, state, INITIAL_STATE);

const setRefinementAttribute = (state: any, { attribute, value }: any) => {
  const { refinementList } = state;
  return Object.assign({}, state, {
    refinementList: { ...refinementList, [attribute]: value },
  });
};

const setSearchState = (state: any, { newState }: any) =>
  Object.assign({}, state, {
    ...newState,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_QUERY]: setQuery,
  [Types.CLEAR_QUERY]: clearQuery,
  [Types.SET_BRAND_ATTR]: setBrandAttr,
  [Types.SET_CAT_ID]: setCatId,
  [Types.SET_PROVINCE]: setProvince,
  [Types.SET_CITY]: setCity,
  [Types.SET_COUNTRY]: setCountry,
  [Types.RESET_SEARCH]: resetSearch,
  [Types.SET_REFINEMENT_ATTRIBUTE]: setRefinementAttribute,
  [Types.SET_SEARCH_STATE]: setSearchState,
});
