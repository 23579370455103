import { put, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import UsersActions from "../../redux/users.redux";
import AuthActions from "../../redux/auth.redux";
import {
  selectUserLoginForm,
  selectChangePasswordForm,
  selectUID,
} from "../selectors";
import { getRandomKey } from "../../utils/functional";
import Toaster from "toasted-notes";

export function* createUser(api: any): any {
  try {
    const userForm = yield select(selectUserLoginForm);
    const uid = yield select(selectUID);
    const response = yield call(api.createUser, { ...userForm, uid });
    if (response.ok) {
      yield put(UsersActions.createUserSuccess(response.data));
      yield put(AuthActions.loginUserRequest(userForm));
      yield put(push("/"));
      Toaster.notify(`Welcome ${userForm.name}`);
    } else {
      yield put(UsersActions.createUserFailure(response.data));
      Toaster.notify(`Could not create user ${userForm.name} at this time.`);
      Toaster.notify(`${response.data?.message}`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createAnon(api: any): any {
  try {
    const response = yield call(api.createAnon);
    if (response.ok) {
      yield put(UsersActions.createAnonSuccess(response.data));
    } else {
      yield put(UsersActions.createAnonFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createUserOauth(api: any, action: any): any {
  try {
    const response = yield call(api.createUserOauth, action.payload);
    if (response.ok) {
      yield put(UsersActions.createUserSuccess(response.data));
      yield put(AuthActions.loginUserOauthRequest(action.payload));
      // yield put(push("/"));
      // Toaster.notify(`Successfully created user ${userForm.name}`);
      Toaster.notify(`Welcome ${action.payload?.user?.displayName}`);
    } else {
      yield put(UsersActions.createUserFailure(response.data));
      // Toaster.notify(`Could not create user ${userForm.name} at this time.`);
      Toaster.notify(`${response.data.message}`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateUserInfo(api: any, action: any): any {
  const { payload, imgType } = action;
  const isCoverImg = imgType && imgType === "coverImg";
  const isPhotoURL = imgType && imgType === "photoURL";
  if (isCoverImg) {
    yield put(UsersActions.setLoadingCoverImg(true));
  }
  if (isPhotoURL) {
    yield put(UsersActions.setLoadingProfileImg(true));
  }
  try {
    const response = yield call(api.updateUserInfo, payload);
    yield put(UsersActions.setEditStatus(false));
    if (response.ok) {
      if (payload instanceof FormData) {
        yield put(UsersActions.getUserByIdRequest(payload.get("uid")));
      }
      yield put(UsersActions.updateUserSuccess());
      if (isCoverImg) {
        yield put(UsersActions.setLoadingCoverImg(getRandomKey(12)));
      }
      if (isPhotoURL) {
        yield put(UsersActions.setLoadingProfileImg(getRandomKey(12)));
      }
    } else {
      yield put(UsersActions.updateUserFailure());
      Toaster.notify(`Could not update user info at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getUserById(api: any, action: any): any {
  const uid = yield select(selectUID);
  try {
    const response = yield call(api.getUserById, uid);
    if (response.ok) {
      yield put(UsersActions.getUserByIdSuccess(response.data));
    } else {
      yield put(push("/"));
      Toaster.notify(`Could not get user info at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* logoutUser(api: any, action: any) {
  yield put(AuthActions.logoutRequest());
  Toaster.notify(`Logout Successful`);
}

export function* changePassword(api: any, action: any): any {
  const { callback } = action;
  try {
    const changePwdForm = yield select(selectChangePasswordForm);
    const uid = yield select(selectUID);
    const response = yield call(api.changePwd, { ...changePwdForm, uid });
    if (response.ok) {
      yield put(UsersActions.changePwdSuccess(response.data));
      Toaster.notify(response?.data?.message);
      callback();
      yield put(push(`/user/${uid}`));
    } else {
      yield put(UsersActions.changePwdFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getProdRecosById(api: any, action: any): any {
  const uid = yield select(selectUID);
  try {
    const response = yield call(api.getProdRecosById, uid);
    if (response.ok) {
      yield put(UsersActions.getProdRecosByIdSuccess(response.data));
    } else {
      yield put(UsersActions.getProdRecosByIdFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
