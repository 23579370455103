import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
// import { ThemeProvider } from "styled-components";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { LanguageProvider } from "./contexts/languages";

import "react-sticky-video/dist/index.css";
import "toasted-notes/src/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "instantsearch.css/themes/satellite.css";
import "sanitize.css/sanitize.css";
import "./index.css";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

import configureStore, { history } from "./store";

const target = document.querySelector("#root");

export const { store, persistor } = configureStore();

export const SanityClient = sanityClient({
  projectId: "rri0nukv",
  dataset: "blog",
  apiVersion: "2021-10-21",
  token: process.env.REACT_APP_SANITY_TOKEN,
  useCdn: false,
});

const builder = imageUrlBuilder(SanityClient);
export const getUrlFor = (source: any) => builder.image(source);

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
  },
});

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <CssBaseline />
          <App />
        </LanguageProvider>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
