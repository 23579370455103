import { path } from "ramda";

export const selectUserLoginForm = (state: any) =>
  path(["userLogin", "values"], state.form);

export const selectUID = (state: any) =>
  path(["users", "currentUser", "uid"], state);

export const selectChangePasswordForm = (state: any) =>
  path(["userChangePassword", "values"], state.form);

export const selectMerchantsLastFetch = (state: any) =>
  path(["merchants", "lastFetch"], state);

export const selectCreateMerchantCurrentStepIdx = (state: any) =>
  path(["merchants", "createMerchantCurrentStepIdx"], state)
