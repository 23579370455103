export default {
  lang: "FR",
  betaVersion: "VERSION BETA",
  companyName: "Nom d'entreprise (légal)",
  operatingAs: "Marque (nom usuel)",
  businessNumber: "Numéro d'entreprise (NEQ ou Inc.)",
  // LOGIN
  alreadyHaveAccount: "Vous avez déjà un compte ? Cliquez pour vous connecter.",
  noAccount: "Première utilisation ? Cliquez pour créer un compte.",
  termsOfService: "Conditions d'utilisation",
  privacyPolicy: "Politique de confidentialité",
  close: "FERMER",
  or: "OU",
  anonymous: "Se Connecter",
  // MAIN
  supportersTitle: "Partenaires de Soutien",
  more: "Plus",
  reviewsTitle: "Commentaires de consommateurs",
  latestBlog: "Publications récentes",
  categories: "Catégories",
  editProfile: "MODIFIER LE PROFIL",
  changePassword: "Changer de Mot de Passe",
  logout: "Me Déconnecter",
  explore: "EXPLORER",
  featured: "Commerces",
  brands: "Marques",
  products: "Produits",
  favorites: "Vos Favoris",
  recentlyViewed: "Vu Récemment",
  forYou: "Pour Toi",
  topPicks: "Coups de Coeur",
  topPicksTitle: "Besoin d'idées ? Parcourez nos Coups de Coeur !",
  topTagline: "Devenir Marque Partenaire",
  // "Les petits commerces sont au coeur de notre économie. #AcheterLocal", // ➤
  latestOnInstagram: "Fil Instagram",
  email: "Courriel",
  password: "Mot de passe",
  city: "Ville",
  province: "Province",
  postalCode: "Code Postal",
  country: "Pays",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedin: "LinkedIn",
  instagram: "Instagram",
  pinterest: "Pinterest",
  businessInfo: "Info sur l'entreprise",
  brandProfile: "Profil de marque",
  founderProfile: "Profil de l'entrepreneur",
  syncCatalog: "Synchroniser le catalogue",
  valuesAttributes: "Attributs de valeur",
  brandsYouMayLike: "Vous aimerez aussi",
  youMayAlsoLike: "Vous aimerez aussi",
  recentlyViewedMerchants: "Récemment visitées",
  loginToFollow: "Veuillez d'abord vous connecter",
  discovery: "Découvrir",
  discover: "DÉCOUVRIR",
  // FOOTER
  copyright: "2021 © Calixa Technologies Inc., tous droits réservés.",
  builtInMtl: "Plateforme développée avec ❤️ à Montréal (Canada)",
  // Design Modal
  selectFromUnsplash: "Choisir sur Unsplash",
  uploadFromDevice: "Téléverser",
  designOnCanva: "Design sur Canva",
  search: "Rechercher",
  searchOnUnsplash: "Rechercher sur Unsplash",
  // Categories
  "15vVHDittZLk26Eg5778": "Mode Maison",
  "3my7NF7EwkV596iKUyQg": "Mode & Style pour Hommes",
  "8AgGKgRl6SgidXKzvOpN": "Accessoires",
  "94gRfYPxWzd47nGuT24s": "Souliers",
  CQWnocIMcHN1JmueWsYA: "Bijoux",
  fS7p0x0OsIfgpC3CCVla: "Lunettes",
  sjlBzcmxcedklFDN3GOJ: "Autres",
  woDxYJncJfpppDRu5mT1: "Beauté & Soins",
  RS83Qex0ocPVZphUYuG2: "Vêtements",
  "4WZwXp2KXy7J5IeF818z": "Maternité",
  womkdrx1MpQ8Nt7X5O8u: "Bébés et Tous-Petits",
  // Merchant Edit Form
  name: "Nom",
  website: "Site Web",
  address: "Adresse",
  phone: "Téléphone",
  description: "Description",
  descriptionFR: "Description (FR)",
  descriptionEN: "Description (EN)",
  tagline: "Slogan",
  taglineFR: "Slogan (FR)",
  taglineEN: "Slogan (EN)",
  cancel: "Annuler",
  update: "Modifier",
  create: "Ajouter",
  addMerchant: "Ajouter un Commerce",
  generalBizInfo: "Information Générale",
  categoriesAndTags: "Catégories et Mots Clés",
  pointsOfSale: "Points de Vente",
  // Login
  loginLoading: "Cela peut prendre quelques secondes...",
  loginPrivateBetaNotice:
    "Nous sommes actuellement en mode bêta privé, accessible uniquement avec un compte d'utilisateur valide. Si vous avez reçu vos identifiants, procédez ci-dessous pour la connexion.",
  loginSignUp:
    "Si vous souhaitez joindre notre bêta privés, veuillez vous inscrire ici :",
  login: "Connexion",
  createAccount: "Créer mon compte",
  // Search
  brandName: "Marque de Commerce",
  keyword: "Mot Clé",
  vegan: "Végane",
  environment: "Environment",
  fairTrade: "Commerce Équitable",
  designer: "Designer",
  handmade: "Créations faites à la main",
  // Top Picks
  forHer: "Pour Elle",
  forHim: "Pour Lui",
  forKids: "Pour Enfants",
  forBabies: "Pour Bébés",
  forAll: "Pour Tous",
  forMoms: "Pour Mamans",
  noResult: "Aucun résultat correspondant à la recherche.",
  // Brand Profile
  brandStoryTitle: "Notre Histoire",
  brandIdentity: "Identité de la Marque",
  highlightVideo: "Vidéo de présentation (URL)",
  owner: "Entrepreneur(e)",
  about: "À propos de ",
  aboutSection: "À propos",
  bg_storyEN: "Parcours (EN)",
  bg_storyFR: "Parcours (FR)",
  noProducts:
    "Aucun produit trouvé. Cliquez pour magasiner directement sur la boutique en ligne.",
  claimProfile:
    "Si vous êtes propriétaire ou directeur(trice) autorisé(e) de cette entreprise, contactez-nous pour réclamer votre profil de commerce et ajouter vos produits.",
  // Product Search
  discoverMoreProducts: "Découvrir plus de produits",
  discoverMoreBrands: "Découvrir plus de commerces",
  // Types
  necklaces: "Colliers",
  earrings: "Boucles d'oreilles",
  rings: "Bagues",
  bracelets: "Bracelets",
  cufflinks: "Boutons de manchette",
  crossbody: "Bandoulière",
  wallet: "Portefeuille",
  tote: "Fourre-tout / Tote",
  hobo: "Hobo",
  backpack: "Sac à dos",
  weekender: "Sac de weekend",
  buyNow: "ACHETER",
  // BADGES
  black_owned: "« Black-Owned »",
  asian_owned: "Entreprenariat Asiatique",
  women_owned: "Entreprenariat Féminin",
  no_animal_testing: "Aucun test sur les animaux",
  sustainable_packaging: "Emballages écologiques",
  made_in_qc: "Fait au Québec",
  peta_approved: "Approuvé par PETA.org",
  made_in_ca: "Fait au Canada",
  tree_planting: "Initiatives de reforestation",
  reduce_plastic: "Réduction de plastique",
  personalization: "Option de personnalisation",
  mental_health: "Soutien à la santé mentale",
  cruelty_free: "« Cruelty-Free »",
  charity_support: "Dons de charité",
  recycle_upcycle: "Matériaux recyclés/re-valorisés",
  fair_trade: "Commerce responsable",
  indigenous_owned: "Entreprenariat Autochtone",
  queer: "Communauté LGBTQ+",
  b_corporation: "Certifié B Corp",
  badge_disclaimer:
    "Ces attributs de profil sont attribués en fonction de l'information accessible au public sur le site Web et les réseaux sociaux de chaque commerce. Calixa n'a pas vérifié ces affirmations de manière indépendante.",
  retailer: "Détaillant multi-marques",
  private_label: "Détaillant",
  fashion_designer: "Designer",
  jewelry_designer: "Designer",
  designer_label: "Designer",
  beauty_maker: "Créateur",
  brand_label: "Marque conceptrice",
  showMore: "Voir plus",
  showLess: "Voir moins",
  // No Auth Msg
  noAuthMessageHeader:
    "Connectez ou créez un compte pour plus de fonctionnalités tels que:",
  userFeature1:
    "Accès rapide aux profiles de marques et aux produits récemment visionnés",
  userFeature2: "Sauvegarder des marques et des produits intéressants",
  userFeature3: "Obtenir des recommandations personnalisées",
  brand_attr: "Valeurs",
  type: "Types",
  cat_id: "Catégories",
  discoverLocalBrandsAndDesigners:
    "Découvrez des désigners et des commerces locaux",
  discoverOutstandingLocalProducts:
    "Découvrez des produits locaux exceptionnels",
  tryVisualDiscovery: "→ Découvrez des produits visuellement (beta)",
  pleaseWait: "Patientez",
  initialLoadingMayTakeAFewSeconds:
    "Le premier chargement peut prendre quelques secondes...",
  searchboxPlaceholder: "Essayez « Fait à Montréal »",
  readMore: "Lire plus",
  read: "Lire",
  brand: "Marques",
  vendor: "Marques",
  discoverBrands: "Découvrir les marques",
  discoverByCat: "Découvrir par catégorie",
  shop: "MAGASINER",
  shopProducts: "Magasiner les produits",
  exploreVisualFeed: "Explorer visuellement",
  viewProduct: "Afficher ce produit",
  findProducts: "Produit(s)",
  // HOMEPAGE
  continueToShopVisual: "Plus, svp!",
  homeTitle:
    "Découvrez des marques et des designers locaux, en toute simplicité",
  homeSubtitle:
    "Les petits commerces sont intrinsèquement plus durables, mais pour les découvrir, il faut souvent un effort beaucoup trop laborieux. Laissez-nous transformer cet expérience pour qu'elle soit plus efficace et ludique!",
  quebec: "Québec",
  ontario: "Ontario",
  britishColumbia: "Colombie-Britannique",
  "British Columbia": "Colombie-Britannique",
  exploreLocal: "Explorez local",
  teamTitle: "Notre Équipe",
  addBrandsTitle:
    "Rejoignez le plus important réseau de commerces indépendents en mode et beauté",
  calixaExperience: "L'Expérience Calixa",
  calixaPromise: "Notre engagement",
  storename: "Nom de la boutique",
  "storename-disclaimer": "Boutiques Shopify seulement pour le moment.",
};
