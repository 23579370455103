import { takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import { UserTypes as Types } from "../../redux/discovery.redux";
import * as Sagas from "./discovery-sagas";

export default [
  takeLatest(Types.GET_DISCOVERY_REQUEST, Sagas.getDiscovery, api),
  takeLatest(
    Types.GET_DISCOVERY_VISUAL_SEARCH_REQUEST,
    Sagas.getDiscoveryVisualSearch,
    api,
  ),
  takeLatest(Types.DELETE_DISCOVERY_DATA, Sagas.deleteDiscoveryData, api),
];
