import { createReducer, createActions } from "reduxsauce";
import _ from "lodash";

const { Types, Creators }: any = createActions({
  addPosRequest: null,
  addPosSuccess: ["payload"],
  addPosFailure: ["error"],
});

export const PosTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
};

const addPosRequest = (state: any) =>
  Object.assign({}, state, { loading: true, data: [], error: null });

const addPosSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, data: _.shuffle(payload.data) });

const addPosFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

// const postTopPickRequest = (state: any) => Object.assign({}, state);

// const postTopPickSuccess = (state: any, { payload }: any) =>
//   Object.assign({}, state, { loading: false, data: payload.data });

// const postTopPickFailure = (state: any, { error }: any) =>
//   Object.assign({}, state, { loading: false, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_POS_REQUEST]: addPosRequest,
  [Types.ADD_POS_SUCCESS]: addPosSuccess,
  [Types.ADD_POS_FAILURE]: addPosFailure,
  // [Types.POST_TOP_PICKS_REQUEST]: postTopPickRequest,
  // [Types.POST_TOP_PICKS_SUCCESS]: postTopPickSuccess,
  // [Types.POST_TOP_PICKS_FAILURE]: postTopPickFailure,
});
