import { put, call } from "redux-saga/effects";
import CanvaActions from "../../redux/canva.redux";
import UsersActions from "../../redux/users.redux";
import MerchantsActions from "../../redux/merchant.redux";
import HubsActions from "../../redux/hub.redux";
import { getRandomKey } from "../../utils/functional";

export function* publishCanva(api: any, action: any): any {
  const { payload } = action;

  try {
    const response = yield call(api.publishCanva, payload);
    if (response.ok) {
      const { profile_id, profile_type, img_type } = payload;
      const isCoverImg = img_type && img_type === "coverImg";
      const isPhotoURL = img_type && img_type === "photoURL";
      if (profile_type === "uid") {
        yield put(UsersActions.getUserByIdRequest(profile_id));
        if (isCoverImg) {
          yield put(UsersActions.setLoadingCoverImg(getRandomKey(12)));
        }
        if (isPhotoURL) {
          yield put(UsersActions.setLoadingProfileImg(getRandomKey(12)));
        }
      }
      if (profile_type === "merchant_id") {
        yield put(MerchantsActions.getMerchantByIdRequest(profile_id));
        if (isCoverImg) {
          yield put(MerchantsActions.setLoadingCoverImg(getRandomKey(12)));
        }
        if (isPhotoURL) {
          yield put(MerchantsActions.setLoadingProfileImg(getRandomKey(12)));
        }
      }
      if (profile_type === "hub_id") {
        yield put(HubsActions.getHubByIdRequest(profile_id));
        if (isCoverImg) {
          yield put(HubsActions.setLoadingCoverImg(getRandomKey(12)));
        }
        if (isPhotoURL) {
          yield put(HubsActions.setLoadingProfileImg(getRandomKey(12)));
        }
      }
      yield put(CanvaActions.publishCanvaSuccess());
    } else {
      yield put(CanvaActions.publishCanvaFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
