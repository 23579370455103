import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const loadingAnimation = () => (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress color="primary" size={35} />
  </Box>
);

export default loadingAnimation;
