import { put, call } from "redux-saga/effects";
import ShopifyActions from "../../redux/shopify.redux";
import Toaster from "toasted-notes";

export function* getShopifyUrl(api: any, action: any): any {
  try {
    const response = yield call(api.getShopifyUrl, action.merchant_id);
    if (response.ok) {
      yield put(ShopifyActions.getShopifyUrlSuccess(response.data));
      window.open(response.data.url, "_blank");
    } else {
      yield put(ShopifyActions.getShopifyUrlFailure(response.data));
      Toaster.notify(`Failed attempt. Please validate the store name again.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getShopifyProducts(api: any, action: any): any {
  const { merchant_id } = action;
  try {
    const response = yield call(api.getShopifyProducts, merchant_id);
    if (response.ok) {
      yield put(ShopifyActions.getShopifyProductsSuccess(response.data));
    } else {
      yield put(ShopifyActions.getShopifyProductsFailure(response.data));
      Toaster.notify(`Could not fetch Shopify Products at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* putShopifyProducts(api: any, action: any): any {
  const { merchant_id } = action;
  try {
    const response = yield call(api.putShopifyProducts, merchant_id);
    if (response.ok) {
      yield put(ShopifyActions.getShopifyProductsSuccess(response.data));
    } else {
      yield put(ShopifyActions.getShopifyProductsFailure(response.data));
      Toaster.notify(`Could not fetch Shopify Products at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}
