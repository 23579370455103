import { takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import { ShopifyTypes as Types } from "../../redux/shopify.redux";
import * as Sagas from "./shopify-sagas";

export default [
  takeLatest(Types.GET_SHOPIFY_URL_REQUEST, Sagas.getShopifyUrl, api),
  takeLatest(Types.GET_SHOPIFY_PRODUCTS_REQUEST, Sagas.getShopifyProducts, api),
  takeLatest(Types.PUT_SHOPIFY_PRODUCTS_REQUEST, Sagas.putShopifyProducts, api),
];
