import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  setScrollY: ["scrollY"],
  setInnerWidth: ["innerWidth"],
  recordEvent: ["event"],
  setMainView: ["view"],
});

export const WindowTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  scrollY: 0,
  innerWidth: null,
  mainView: "products",
};

const setScrollY = (state: any, { scrollY }: any) =>
  Object.assign({}, state, { scrollY });
const setInnerWidth = (state: any, { innerWidth }: any) =>
  Object.assign({}, state, { innerWidth });

const setMainView = (state: any, { view }: any) =>
  Object.assign({}, state, { mainView: view });

const recordEvent = (state: any) => Object.assign({}, state);

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SCROLL_Y]: setScrollY,
  [Types.SET_INNER_WIDTH]: setInnerWidth,
  [Types.SET_MAIN_VIEW]: setMainView,
  [Types.RECORD_EVENT]: recordEvent,
});
