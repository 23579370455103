import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  createUserOauthRequest: ["payload"],
  createUserRequest: null,
  createUserSuccess: ["payload"],
  createUserFailure: ["error"],
  createAnonRequest: null,
  createAnonSuccess: ["payload"],
  createAnonFailure: ["error"],
  updateUserRequest: ["payload", "imgType"],
  updateUserSuccess: ["payload"],
  updateUserFailure: ["error"],
  getUserByIdRequest: ["uid"],
  getUserByIdSuccess: ["payload"],
  getUserByIdFailure: ["error"],
  setUserAfterAuth: ["user"],
  setLoadingCoverImg: ["val"],
  setLoadingProfileImg: ["val"],
  setEditStatus: ["val"],
  changePwdRequest: ["callback"],
  changePwdSuccess: ["payload"],
  changePwdFailure: ["error"],
  clearError: null,
  clearCurrentUser: null,
  getProdRecosByIdRequest: ["uid"],
  getProdRecosByIdSuccess: ["payload"],
  getProdRecosByIdFailure: ["error"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  creating: false,
  data: [],
  prodRecos: [],
  error: null,
  loadingCoverImg: false,
  loadingProfileImg: false,
  loading: false,
  loadingProdRecos: false,
  updating: false,
  currentUser: null,
};

const createUserOauthRequest = (state: any) =>
  Object.assign({}, state, { creating: true });

const createUserRequest = (state: any) =>
  Object.assign({}, state, { creating: true });

const createUserSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { creating: false, currentUser: payload.payload });

const createUserFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { creating: false, error });

const createAnonRequest = (state: any) =>
  Object.assign({}, state, { creating: true });

const createAnonSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { creating: false, currentUser: payload.payload });

const createAnonFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { creating: false, error });

const updateUserRequest = (state: any) =>
  Object.assign({}, state, { loading: true });

const updateUserSuccess = (state: any, { payload }: any) => {
  return Object.assign({}, state, {
    currentUser: payload,
    loading: false,
  });
};

const updateUserFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false });

const getUserByIdRequest = (state: any) => Object.assign({}, state);

const getUserByIdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { currentUser: payload });

const getUserByIdFailure = (state: any, { error }: any) =>
  Object.assign({}, state);

const setUserAfterAuth = (state: any, { user }: any) =>
  Object.assign({}, state, { currentUser: user });

const setLoadingCoverImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingCoverImg: val });
const setLoadingProfileImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingProfileImg: val });

const setEditStatus = (state: any, { val }: any) =>
  Object.assign({}, state, { edit: val });

const changePwdRequest = (state: any) =>
  Object.assign({}, state, { loading: true, error: null });

const changePwdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false });

const changePwdFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const clearError = (state: any) => Object.assign({}, state, { error: null });

const clearCurrentUser = (state: any) =>
  Object.assign({}, state, { currentUser: null });

const getProdRecosByIdRequest = (state: any) =>
  Object.assign({}, state, {
    loadingProdRecos: true,
  });

const getProdRecosByIdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loadingProdRecos: false,
    prodRecos: payload,
  });

const getProdRecosByIdFailure = (state: any, { error }: any) =>
  Object.assign({}, state, {
    loadingProdRecos: false,
    error,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_USER_OAUTH_REQUEST]: createUserOauthRequest,
  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.CREATE_ANON_REQUEST]: createAnonRequest,
  [Types.CREATE_ANON_SUCCESS]: createAnonSuccess,
  [Types.CREATE_ANON_FAILURE]: createAnonFailure,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.GET_USER_BY_ID_REQUEST]: getUserByIdRequest,
  [Types.GET_USER_BY_ID_SUCCESS]: getUserByIdSuccess,
  [Types.GET_USER_BY_ID_FAILURE]: getUserByIdFailure,
  [Types.SET_USER_AFTER_AUTH]: setUserAfterAuth,
  [Types.SET_LOADING_COVER_IMG]: setLoadingCoverImg,
  [Types.SET_LOADING_PROFILE_IMG]: setLoadingProfileImg,
  [Types.SET_EDIT_STATUS]: setEditStatus,
  [Types.CHANGE_PWD_REQUEST]: changePwdRequest,
  [Types.CHANGE_PWD_SUCCESS]: changePwdSuccess,
  [Types.CHANGE_PWD_FAILURE]: changePwdFailure,
  [Types.CLEAR_ERROR]: clearError,
  [Types.CLEAR_CURRENT_USER]: clearCurrentUser,
  [Types.GET_PROD_RECOS_BY_ID_REQUEST]: getProdRecosByIdRequest,
  [Types.GET_PROD_RECOS_BY_ID_SUCCESS]: getProdRecosByIdSuccess,
  [Types.GET_PROD_RECOS_BY_ID_FAILURE]: getProdRecosByIdFailure,
});
