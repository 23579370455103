import { takeLatest, takeEvery } from "redux-saga/effects";
import api from "../../services/api";

import { UserTypes as Types } from "../../redux/merchant.redux";
import * as Sagas from "./merchant-sagas";

export default [
  takeLatest(Types.GET_MERCHANTS_REQUEST, Sagas.getMerchants, api),
  takeLatest(Types.GET_MERCHANT_BY_ID_REQUEST, Sagas.getMerchantById, api),
  takeLatest(Types.PUT_MERCHANT_REQUEST, Sagas.putMerchant, api),
  takeLatest(Types.CREATE_MERCHANT_REQUEST, Sagas.createMerchant, api),
  takeLatest(Types.GET_MERCHANT_IG_LATEST_REQUEST, Sagas.getIgByMerchant, api),
  takeLatest(Types.GET_MERCHANTS_RECO_REQUEST, Sagas.getMerchantsReco, api),
  takeLatest(
    Types.GET_MERCHANTS_RECENTLY_VIEW_REQUEST,
    Sagas.getMerchantsRecentlyView,
    api
  ),
  takeLatest(
    Types.UPDATE_MERCHANT_LIKE_REQUEST,
    Sagas.updateMerchantLikeRequest,
    api
  ),
  takeLatest(Types.GET_MERCHANTS_MORE_REQUEST, Sagas.getMerchantsMore, api),
  takeLatest(Types.GET_MERCHANTS_SEARCH_REQUEST, Sagas.getMerchantsSearch, api),
  takeEvery(Types.GET_MERCHANTS_BY_CAT_REQUEST, Sagas.getMerchantsByCat, api),
  takeEvery(Types.GET_MERCHANTS_BY_ATTR_REQUEST, Sagas.getMerchantsByAttr, api),
];
