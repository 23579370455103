import React from "react";
import LoadingAnimation from "../../components/loading-animation";
import Logo from "../../assets/graphics/calixa_woicon_black.svg";
import Section from "../../components/section";

const LoadingPage = () => (
  <Section
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    style={{ width: "100vw", height: "100vh" }}
  >
    <Section style={{ marginBottom: "50px" }}>
      <img src={Logo} width="200" alt="Calixa" />
    </Section>
    <LoadingAnimation />
  </Section>
);

export default LoadingPage;