import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  getShopifyUrlRequest: ["merchant_id"],
  getShopifyUrlSuccess: ["payload"],
  getShopifyUrlFailure: ["error"],
  getShopifyProductsRequest: ["merchant_id"],
  getShopifyProductsSuccess: ["payload"],
  getShopifyProductsFailure: ["error"],
  putShopifyProductsRequest: ["merchant_id"],
});

export const ShopifyTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: {},
  error: null,
};

const getShopifyUrlRequest = (state: any) =>
  Object.assign({}, state, { loading: true });
const getShopifyUrlSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false });
const getShopifyUrlFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getShopifyProductsRequest = (state: any) =>
  Object.assign({}, state, { loading: true, currentShopify: null });
const getShopifyProductsSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, currentShopify: payload });
const getShopifyProductsFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const putShopifyProductsRequest = (state: any) =>
  Object.assign({}, state, { loading: true, currentShopify: null });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SHOPIFY_URL_REQUEST]: getShopifyUrlRequest,
  [Types.GET_SHOPIFY_URL_SUCCESS]: getShopifyUrlSuccess,
  [Types.GET_SHOPIFY_URL_FAILURE]: getShopifyUrlFailure,
  [Types.GET_SHOPIFY_PRODUCTS_REQUEST]: getShopifyProductsRequest,
  [Types.GET_SHOPIFY_PRODUCTS_SUCCESS]: getShopifyProductsSuccess,
  [Types.GET_SHOPIFY_PRODUCTS_FAILURE]: getShopifyProductsFailure,
  [Types.PUT_SHOPIFY_PRODUCTS_REQUEST]: putShopifyProductsRequest,
});
