import { takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import { UserTypes as Types } from "../../redux/users.redux";
import * as Sagas from "./users-sagas";

export default [
  takeLatest(Types.CREATE_USER_REQUEST, Sagas.createUser, api),
  takeLatest(Types.CREATE_ANON_REQUEST, Sagas.createAnon, api),
  takeLatest(Types.CREATE_USER_OAUTH_REQUEST, Sagas.createUserOauth, api),
  takeLatest(Types.UPDATE_USER_REQUEST, Sagas.updateUserInfo, api),
  takeLatest(Types.GET_USER_BY_ID_REQUEST, Sagas.getUserById, api),
  takeLatest(Types.CHANGE_PWD_REQUEST, Sagas.changePassword, api),
  takeLatest(Types.GET_PROD_RECOS_BY_ID_REQUEST, Sagas.getProdRecosById, api),
];
