import { put, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import MerchantsActions from "../../redux/merchant.redux";
import { getRandomKey } from "../../utils/functional";
import { selectCreateMerchantCurrentStepIdx, selectUID } from "../selectors";

export function* getMerchants(api: any, action: any): Generator<any, any, any> {
  const { query } = action;
  const uid: string = yield select(selectUID);
  try {
    const response: any = yield call(api.getMerchants, query);
    if (response.ok) {
      const payload = response.data;
      const auth_inReview = ["Byx9gXWDTtM7H2v8YQta", "nP3lGFwkBk1ZFPuG2XKQ"];
      if (auth_inReview.includes(uid)) {
        yield put(MerchantsActions.getMerchantsSuccess(payload));
      } else {
        const filtered = payload.data.filter(
          (m: any) => m.curation.status === "activated"
        );
        yield put(
          MerchantsActions.getMerchantsSuccess({
            data: filtered,
            offset: payload.offset,
            showMoreDone: payload.showMoreDone,
          })
        );
      }
    } else {
      yield put(MerchantsActions.getMerchantsFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantById(
  api: any,
  action: any
): Generator<any, any, any> {
  const { merchant_id } = action;
  const uid: string = yield select(selectUID);
  try {
    const response: any = yield call(api.getMerchantById, merchant_id, uid);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantByIdSuccess(response.data));
    } else {
      yield put(MerchantsActions.getMerchantByIdFailure(response.data));
      yield put(push("/"));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getIgByMerchant(
  api: any,
  action: any
): Generator<any, any, any> {
  const { merchant_id } = action;
  try {
    const response: any = yield call(api.getIgByMerchantLatest, merchant_id);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantIgLatestSuccess());
      // yield put(MerchantsActions.getMerchantByIdRequest(merchant_id));
    } else {
      yield put(MerchantsActions.getMerchantIgLatestFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* putMerchant(api: any, action: any): Generator<any, any, any> {
  const { payload, imgType } = action;
  const isCoverImg = imgType && imgType === "coverImg";
  const isPhotoURL = imgType && imgType === "photoURL";
  const isDesignerImg = imgType && imgType === "designerImg";
  if (isCoverImg) {
    yield put(MerchantsActions.setLoadingCoverImg(true));
  }
  if (isPhotoURL) {
    yield put(MerchantsActions.setLoadingProfileImg(true));
  }
  if (isDesignerImg) {
    yield put(MerchantsActions.setLoadingDesignerImg(true));
  }
  try {
    const response: any = yield call(api.putMerchant, payload);
    yield put(MerchantsActions.setEditStatus(false));
    if (response.ok) {
      if (payload instanceof FormData) {
        yield put(
          MerchantsActions.getMerchantByIdRequest(payload.get("merchant_id"))
        );
      }
      yield put(MerchantsActions.putMerchantSuccess(payload));
      if (isCoverImg) {
        yield put(MerchantsActions.setLoadingCoverImg(getRandomKey(12)));
      }
      if (isPhotoURL) {
        yield put(MerchantsActions.setLoadingProfileImg(getRandomKey(12)));
      }
      if (isDesignerImg) {
        yield put(MerchantsActions.setLoadingDesignerImg(getRandomKey(12)));
      }
    } else {
      yield put(MerchantsActions.putMerchantFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createMerchant(
  api: any,
  action: any
): Generator<any, any, any> {
  const { payload } = action;
  const uid = yield select(selectUID);
  const idx = yield select(selectCreateMerchantCurrentStepIdx);
  try {
    const response: any = yield call(api.postMerchant, {
      uid,
      merchant: payload,
    });
    if (response.ok) {
      yield put(MerchantsActions.createMerchantSuccess(response.data));
      yield put(MerchantsActions.setCreateMerchantCurrentStepIdx(idx + 1));
    } else {
      yield put(MerchantsActions.createMerchantFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsReco(api: any): Generator<any, any, any> {
  const uid = yield select(selectUID);
  try {
    const response: any = yield call(api.getMerchantsReco, { uid: uid });
    if (response.ok) {
      yield put(MerchantsActions.getMerchantsRecoSuccess(response.data));
    } else {
      yield put(MerchantsActions.getMerchantsRecoFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsRecentlyView(api: any): Generator<any, any, any> {
  const uid = yield select(selectUID);
  try {
    const response: any = yield call(api.getMerchantsRecentlyView, {
      uid: uid,
    });
    if (response.ok) {
      yield put(
        MerchantsActions.getMerchantsRecentlyViewSuccess(response.data)
      );
    } else {
      yield put(
        MerchantsActions.getMerchantsRecentlyViewFailure(response.data)
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsMore(
  api: any,
  action: any
): Generator<any, any, any> {
  const { payload } = action;
  try {
    const response = yield call(api.getMerchants, payload);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantsMoreSuccess(response.data));
    } else {
      yield put(MerchantsActions.getMerchantsMoreFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateMerchantLikeRequest(
  api: any,
  action: any
): Generator<any, any, any> {
  const uid = yield select(selectUID);
  try {
    const response: any = yield call(api.postMerchantLike, {
      uid: uid,
      id: action.payload.id,
      type: action.payload.type,
      likeState: action.payload.likeState || false,
    });

    if (response.ok) {
      if (action.payload.type === "merchants") {
        yield put(MerchantsActions.updateMerchantLikeSuccess(response.data));
      } else {
        yield put(
          MerchantsActions.updateProductLikeSuccess({
            likeState: response.data || false,
            id: action.payload.id,
          })
        );
      }
    } else {
      yield put(MerchantsActions.updateMerchantLikeFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsSearch(
  api: any,
  action: any
): Generator<any, any, any> {
  const { keyword } = action;
  try {
    const response = yield call(api.getMerchantSearch, keyword);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantsSearchSuccess(response.data));
    } else {
      yield put(MerchantsActions.getMerchantsSearchFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsByCat(
  api: any,
  action: any
): Generator<any, any, any> {
  const { cat_id } = action;
  try {
    const response: any = yield call(api.getMerchantsByCat, cat_id);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantsByCatSuccess(response.data));
    } else {
      yield put(MerchantsActions.get(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsByAttr(
  api: any,
  action: any
): Generator<any, any, any> {
  const { brand_attr } = action;
  try {
    const response: any = yield call(api.getMerchantsByAttr, brand_attr);
    if (response.ok) {
      yield put(MerchantsActions.getMerchantsByAttrSuccess(response.data));
    } else {
      yield put(MerchantsActions.get(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
