import { createReducer, createActions } from "reduxsauce";

const { Types, Creators }: any = createActions({
  publishCanvaRequest: ["payload"],
  publishCanvaSuccess: ["payload"],
  publishCanvaFailure: ["error"],
  setCanvaId: ["design_id"],
  clearCanvaId: null,
});

export const CanvaTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  current_canva: null,
  publishing: false,
  error: null,
};

const publishCanvaRequest = (state: any) =>
  Object.assign({}, state, { publishing: true });
const publishCanvaSuccess = (state: any) =>
  Object.assign({}, state, { publishing: false });
const publishcanvaFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { publishing: false, error });

const setCanvaId = (state: any, { design_id }: any) =>
  Object.assign({}, state, { current_canva: design_id });

const clearCanvaId = (state: any) =>
  Object.assign({}, state, { current_canva: null });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PUBLISH_CANVA_REQUEST]: publishCanvaRequest,
  [Types.PUBLISH_CANVA_SUCCESS]: publishCanvaSuccess,
  [Types.PUBLISH_CANVA_FAILURE]: publishcanvaFailure,
  [Types.SET_CANVA_ID]: setCanvaId,
  [Types.CLEAR_CANVA_ID]: clearCanvaId,
});
