import { all } from "redux-saga/effects";
import AuthListeners from "./auth";
import EventsListeners from "./events";
import UsersListeners from "./users";
import GeneralListeners from "./general/general-listeners";
import MerchantsListeners from "./merchants";
import ProductsListeners from "./products";
import HubListeners from "./hub";
import CanvaListeners from "./canva";
import UnsplashListeners from "./unsplash";
import ShopifyListeners from "./shopify";
import TopPicksListeners from "./top-picks";
import DiscoveryListeners from "./discovery";

export default function* root() {
  yield all([
    ...AuthListeners,
    ...EventsListeners,
    ...UsersListeners,
    ...GeneralListeners,
    ...MerchantsListeners,
    ...ProductsListeners,
    ...HubListeners,
    ...UnsplashListeners,
    ...CanvaListeners,
    ...ShopifyListeners,
    ...TopPicksListeners,
    ...DiscoveryListeners,
  ]);
}
