// Transform Merchants Payload from DB

export const TransformMerchantForSearch = (merchants: any) =>
  merchants.map((merchant: any) => {
    const {
      description,
      name,
      tagline,
      badges_highlight,
      tags = [],
    } = merchant;
    const langBased = [description, tagline];
    const combined = langBased.map((e) => e?.FR + " " + e?.EN).join(" ");
    const badges = (badges_highlight && Object.keys(badges_highlight)) || [];

    return {
      ...merchant,
      tags: tags.concat(badges),
      search_content: name?.toLowerCase() + " " + combined.toLowerCase(),
    };
  });
