import { takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import { AuthTypes as Types } from "../../redux/auth.redux";
import * as Sagas from "./auth-sagas";

export default [
  takeLatest(Types.LOGIN_USER_REQUEST, Sagas.localLogin, api),
  takeLatest(Types.LOGIN_USER_ONE_TAP_REQUEST, Sagas.oneTapLogin, api),
  takeLatest(Types.LOGIN_USER_OAUTH_REQUEST, Sagas.oauthLogin, api),
];
