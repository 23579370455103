import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import persistReducer from "redux-persist/lib/persistReducer";
import persistStore from "redux-persist/lib/persistStore";
import storage from "redux-persist/lib/storage";
import rootReducer from "./redux";
import sagas from "./sagas";

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export default () => {
  const initialState = {};
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = {
    key: "root",
    version: 4,
    storage,
    stateReconciler: autoMergeLevel2,
  };

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  const store = createStore(persistedReducer, initialState, composedEnhancers);

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { store, persistor };
};
