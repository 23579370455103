export default {
  lang: "EN",
  betaVersion: "BETA VERSION",
  companyName: "Company Name (as registered)",
  operatingAs: "Brand (operating as)",
  businessNumber: "Business Registration Number",
  // LOGIN
  alreadyHaveAccount: "Already have an account? Click to login.",
  noAccount: "Don't have an account yet? Click to create an account.",
  termsOfService: "Terms of Service",
  privacyPolicy: "Privacy Policy",
  close: "CLOSE",
  or: "OR",
  anonymous: "Login",
  // MAIN
  supportersTitle: "Partners & Supporters",
  more: "More",
  reviewsTitle: "What Shoppers Said",
  latestBlog: "Latest Posts",
  categories: "Categories",
  editProfile: "EDIT PROFILE",
  changePassword: "Change Password",
  logout: "Logout",
  explore: "EXPLORE",
  featured: "Brands",
  brands: "Brands",
  products: "Products",
  favorites: "Your Favorites",
  recentlyViewed: "Recently Viewed",
  forYou: "For You",
  topPicks: "Top Picks",
  topPicksTitle: "Need Ideas? Browse our Top Picks!",
  topTagline: "Become a Partner Brand", // "Small businesses are the core of our economy. #ShopLocal",
  latestOnInstagram: "Latest on Instagram",
  email: "Email",
  password: "Password",
  city: "City",
  province: "Province",
  postalCode: "Postal Code",
  country: "Country",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedin: "LinkedIn",
  instagram: "Instagram",
  pinterest: "Pinterest",
  businessInfo: "Business Information",
  brandProfile: "Brand Profile",
  founderProfile: "Founder Profile",
  syncCatalog: "Sync Catalog",
  valuesAttributes: "Value-Based Attributes",
  brandsYouMayLike: "Brands You May Like",
  youMayAlsoLike: "You May Also Like",
  recentlyViewedMerchants: "Recently Viewed",
  loginToFollow: "Please login first",
  discovery: "Discovery",
  discover: "DISCOVER",
  // FOOTER
  copyright: "2021 © Calixa Technologies Inc., All Rights Reserved",
  builtInMtl: "Built with ❤️ in Montreal (Canada)",
  // Design Modal
  selectFromUnsplash: "Select from Unsplash",
  uploadFromDevice: "Upload from Device",
  designOnCanva: "Design on Canva",
  search: "Search",
  searchOnUnsplash: "Search on Unsplash",
  // Categories
  "15vVHDittZLk26Eg5778": "Home Fashion",
  "3my7NF7EwkV596iKUyQg": "Men's Style & Fashion",
  "8AgGKgRl6SgidXKzvOpN": "Accessories",
  "94gRfYPxWzd47nGuT24s": "Shoes",
  CQWnocIMcHN1JmueWsYA: "Jewelry",
  fS7p0x0OsIfgpC3CCVla: "Eyewear",
  sjlBzcmxcedklFDN3GOJ: "Others",
  woDxYJncJfpppDRu5mT1: "Beauty & Care",
  RS83Qex0ocPVZphUYuG2: "Apparel",
  "4WZwXp2KXy7J5IeF818z": "Maternity",
  womkdrx1MpQ8Nt7X5O8u: "Babies & Toddlers",
  // Merchant Edit Form
  name: "Name",
  website: "Website",
  address: "Address",
  phone: "Phone",
  description: "Description",
  descriptionFR: "Description (FR)",
  descriptionEN: "Description (EN)",
  tagline: "Tagline",
  taglineFR: "Tagline (FR)",
  taglineEN: "Tagline (EN)",
  cancel: "Cancel",
  update: "Update",
  create: "Create",
  addMerchant: "Add New Brand",
  generalBizInfo: "General Business Information",
  categoriesAndTags: "Categories and Tags",
  pointsOfSale: "Points of Sale",
  // Login
  loginLoading: "This may take a few seconds...",
  loginPrivateBetaNotice:
    "We are currently in Private Beta mode, accessible only with a valid user account. If you have received your user credentials, please proceed.",
  loginSignUp:
    "If you would like to get on our Private Beta list, please sign-up here:",
  login: "Login",
  createAccount: "Create Account",
  // Search
  brandName: "Brand Name",
  keyword: "Keyword",
  vegan: "Vegan",
  environment: "Environment",
  fairTrade: "Fair Trade",
  designer: "Designer",
  handmade: "Handmade",
  // Top Picks
  forHer: "For Her",
  forHim: "For Him",
  forKids: "For Kids",
  forBabies: "For Babies",
  forAll: "For All",
  forMoms: "For Moms",
  noResult: "No results found.",
  // Brand Profile
  brandStoryTitle: "Our Story",
  brandIdentity: "Brand Identity",
  highlightVideo: "Highlight Video (URL)",
  owner: "Founder",
  about: "About ",
  aboutSection: "About",
  bg_storyEN: "Background Story (EN)",
  bg_storyFR: "Background Story (FR)",
  noProducts: "No products found. Click to shop on Brand's web store.",
  claimProfile:
    "If you are an owner or authorized director of this business, contact us to claim your business profile and add your products.",
  // Product Search
  discoverMoreProducts: "Discover More Products",
  discoverMoreBrands: "Discover More Brands",
  // Types
  necklaces: "Necklaces",
  earrings: "Earrings",
  rings: "Rings",
  bracelets: "Bracelets",
  cufflinks: "Cufflinks",
  crossbody: "Crossbody",
  wallet: "Wallet",
  tote: "Tote",
  hobo: "Hobo",
  backpack: "Backpack",
  weekender: "Weekender",
  buyNow: "BUY NOW",
  // BADGES
  black_owned: "Black-Owned",
  asian_owned: "Asian-Owned",
  women_owned: "Women-Owned",
  no_animal_testing: "Not Tested on Animals",
  sustainable_packaging: "Low Env. Impact Packaging",
  made_in_qc: "Made in Quebec",
  peta_approved: "PETA-Approved Vegan",
  made_in_ca: "Made in Canada",
  tree_planting: "Tree Planting Initiatives",
  reduce_plastic: "Recycle and/or Reduce Plastic",
  personalization: "Personalization Option",
  mental_health: "Initiatives for Mental Health",
  cruelty_free: "Cruelty-Free",
  charity_support: "Donations to Charity",
  recycle_upcycle: "Recycled & Upcycled Materials",
  fair_trade: "Fair & Ethical Practices",
  indigenous_owned: "Indigenous-Owned",
  queer: "LGBTQ+ Community",
  b_corporation: "B Corp Certified",
  badge_disclaimer:
    "Badges are assigned based on publicly available information on the brand's website and social networks. Calixa has not independently verified these claims.",
  retailer: "Multi-Brand Retailer",
  private_label: "Brand",
  fashion_designer: "Designer",
  jewelry_designer: "Designer",
  designer_label: "Designer",
  beauty_maker: "Creator",
  brand_label: "Brand Label",
  showMore: "Show More",
  showLess: "Show Less",
  noAuthMessageHeader: "Login or create an account for features like:",
  userFeature1: "Quick access to recently viewed brands and products",
  userFeature2: "Save brands and products you like to your profile",
  userFeature3: "Get personalized recommendations",
  brand_attr: "Values",
  type: "Types",
  cat_id: "Categories",
  discoverLocalBrandsAndDesigners: "Discover Local Brands & Designers",
  discoverOutstandingLocalProducts: "Discover Outstanding Local Products",
  tryVisualDiscovery: "Try our Visual Discovery Experience (beta)",
  pleaseWait: "Please Wait",
  initialLoadingMayTakeAFewSeconds: "Initial loading may take a few seconds...",
  searchboxPlaceholder: 'Try "Made in Montreal"',
  readMore: "Read More",
  read: "Read",
  brand: "Brands",
  vendor: "Brands",
  discoverBrands: "Discover Brands",
  discoverByCat: "Discover by Category",
  shop: "SHOP",
  shopProducts: "Shop Products",
  exploreVisualFeed: "Explore Visual Feed",
  viewProduct: "View Product",
  findProducts: "Find Product(s)",
  // HOMEPAGE
  continueToShopVisual: "More please!",
  homeTitle: "Discover local brands & designers, without the hassle",
  homeSubtitle:
    "Small brands are inherently more sustainable, but discovering is often a very time-consuming and unreliable effort. It doesn’t have to be this way. Let us to make this convenient and delightful!",
  quebec: "Quebec",
  ontario: "Ontario",
  britishColumbia: "British Columbia",
  "British Columbia": "British Columbia",
  exploreLocal: "Explore nearby",
  teamTitle: "Our Team",
  addBrandsTitle:
    "Join the Next Top Destination for Emerging Fashion & Beauty Brands",
  calixaExperience: "The Calixa Experience",
  calixaPromise: "The Calixa Promise",
  storename: "Storename",
  "storename-disclaimer": "Shopify Stores only at this time.",
};
