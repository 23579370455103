import { createReducer, createActions } from "reduxsauce";
import _ from "lodash";

const { Types, Creators }: any = createActions({
  getDiscoveryRequest: ["payload"],
  getDiscoverySuccess: ["payload"],
  getDiscoveryFailure: ["error"],
  getDiscoveryVisualSearchRequest: ["payload"],
  getDiscoveryVisualSearchSuccess: ["payload"],
  getDiscoveryVisualSearchFailure: ["error"],
  deleteDiscoveryData: ["id"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: [],
  lastTimestamp: null,
  lastFetch: null,
  error: null,
  visualProductSearchLoading: false,
  visualProductSearchData: null,
};

const getDiscoveryRequest = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: true,
    data: payload ? state.data : [],
  });

const getDiscoverySuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    data: state.data.concat(_.shuffle(payload.data)),
    lastTimestamp: payload.data[payload.data.length - 1].timestamp,
  });

const getDiscoveryFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getDiscoveryVisualSearchRequest = (state: any) =>
  Object.assign({}, state, { visualProductSearchLoading: true });

const getDiscoveryVisualSearchSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    visualProductSearchLoading: false,
    visualProductSearchData: payload.data,
  });

const getDiscoveryVisualSearchFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { visualProductSearchLoading: false, error });

const deleteDiscoveryData = (state: any, { id }: any) => {
  return Object.assign({}, state, {
    data: state.data.filter((v: any) => v.doc_id !== id),
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DISCOVERY_REQUEST]: getDiscoveryRequest,
  [Types.GET_DISCOVERY_SUCCESS]: getDiscoverySuccess,
  [Types.GET_DISCOVERY_FAILURE]: getDiscoveryFailure,
  [Types.GET_DISCOVERY_VISUAL_SEARCH_REQUEST]: getDiscoveryVisualSearchRequest,
  [Types.GET_DISCOVERY_VISUAL_SEARCH_SUCCESS]: getDiscoveryVisualSearchSuccess,
  [Types.GET_DISCOVERY_VISUAL_SEARCH_FAILURE]: getDiscoveryVisualSearchFailure,
  [Types.DELETE_DISCOVERY_DATA]: deleteDiscoveryData,
});
