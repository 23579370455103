import { put, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import HubActions from "../../redux/hub.redux";
import { getRandomKey } from "../../utils/functional";
import { selectUID } from "../selectors";
import Toaster from "toasted-notes";

const elapsedHours = (dt2: Date, dt1: Date) => {
  if (dt2 instanceof Date && dt1 instanceof Date) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  } else {
    return;
  }
};

const isInstagramLink = (ig_link: string) => {
  if (ig_link === "") {
    return;
  }
  if (!ig_link) {
    return;
  }
  if (ig_link?.includes("instagram.com/")) {
    return true;
  }
};

export function* getHubs(api: any): any {
  try {
    const response = yield call(api.getHubs);
    if (response.ok) {
      yield put(HubActions.getHubsSuccess(response.data));
      yield put(push("/"));
    } else {
      yield put(HubActions.getHubsFailure(response.data));
      Toaster.notify(`Could not fetch hub at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getHubById(api: any, action: any): any {
  const { hub_id } = action;
  try {
    const uid = yield select(selectUID);
    const response = yield call(api.getHubById, hub_id, uid);
    if (response.ok) {
      const { hub_id, instagram, ig_box_timestamp } = response.data;
      if (ig_box_timestamp) {
        const elapsedHrs =
          elapsedHours(new Date(ig_box_timestamp), new Date()) || 0;
        if (elapsedHrs >= 6 && isInstagramLink(instagram)) {
          yield put(HubActions.getIgLatestRequest(hub_id, instagram));
        }
      } else if (isInstagramLink(instagram)) {
        yield put(HubActions.getIgLatestRequest(hub_id, instagram));
      }
      yield put(HubActions.getHubByIdSuccess(response.data));
    } else {
      yield put(HubActions.getHubByIdFailure(response.data));
      Toaster.notify(`Could not fetch hub at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getIgByHub(api: any, action: any): any {
  const { hub_id, instagram } = action;
  const payload = { hub_id, instagram };
  try {
    const response = yield call(api.getIgByHubLatest, payload);
    if (response.ok) {
      yield put(HubActions.getIgLatestSuccess(response.data.data));
      // yield put(push('/hub'))
    } else {
      yield put(HubActions.getIgLatestFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getMerchantsByHub(api: any, action: any): any {
  const { hub_id } = action;

  try {
    const response = yield call(api.getMerchantsByHub, hub_id);
    if (response.ok) {
      yield put(HubActions.getMerchantsByHubSuccess(response.data));
      // yield put(push('/hub'))
    } else {
      yield put(HubActions.getMerchantsByHubFailure(response.data));
      Toaster.notify(`Could not fetch merchants at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateHub(api: any, action: any): any {
  const { payload, imgType } = action;
  const isCoverImg = imgType && imgType === "coverImg";
  const isPhotoURL = imgType && imgType === "photoURL";
  if (isCoverImg) {
    yield put(HubActions.setLoadingCoverImg(true));
  }
  if (isPhotoURL) {
    yield put(HubActions.setLoadingProfileImg(true));
  }
  try {
    const response = yield call(api.updateHub, payload);
    yield put(HubActions.setEditStatus(false));
    if (response.ok) {
      if (payload instanceof FormData) {
        yield put(
          HubActions.getHubByIdRequest(
            payload.get("hub_id"),
            payload.get("uid")
          )
        );
      }
      yield put(HubActions.updateHubSuccess(payload));
      if (isCoverImg) {
        yield put(HubActions.setLoadingCoverImg(getRandomKey(12)));
      }
      if (isPhotoURL) {
        yield put(HubActions.setLoadingProfileImg(getRandomKey(12)));
      }
    } else {
      yield put(HubActions.updateHubFailure(response.data));
      Toaster.notify(`Could not update hub at this time.`);
    }
  } catch (error) {
    console.error(error);
  }
}
