import { createReducer, createActions } from "reduxsauce";
import _ from "lodash";

const { Types, Creators }: any = createActions({
  getHubsRequest: null,
  getHubsSuccess: ["payload"],
  getHubsFailure: ["error"],
  getHubByIdRequest: ["hub_id"],
  getHubByIdSuccess: ["payload"],
  getHubByIdFailure: ["error"],
  getMerchantsByHubRequest: ["hub_id"],
  getMerchantsByHubSuccess: ["payload"],
  getMerchantsByHubFailure: ["error"],
  getIgLatestRequest: ["hub_id", "instagram"],
  getIgLatestSuccess: ["payload"],
  getIgLatestFailure: ["error"],
  updateHubRequest: ["payload", "imgType"],
  updateHubSuccess: ["payload"],
  updateHubFailure: ["error"],
  setLoadingCoverImg: ["val"],
  setLoadingProfileImg: ["val"],
  setEditStatus: ["val"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
  loadingCoverImg: false,
  loadingProfileImg: false,
  loadingIgBox: false,
  edit: false,
};

const getHubsRequest = (state: any) =>
  Object.assign({}, state, { loading: true });
const getHubsSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, data: _.shuffle(payload.data) });
const getHubsFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getHubByIdRequest = (state: any) =>
  Object.assign({}, state, { loading: true });
const getHubByIdSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, currentHub: payload });
const getHubByIdFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const getMerchantsByHubRequest = (state: any) =>
  Object.assign({}, state, { loading: true, data: [] });

const getMerchantsByHubSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, data: payload.data });

const getMerchantsByHubFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

const updateHubRequest = (state: any) =>
  Object.assign({}, state, { loading: true });
const updateHubSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loading: false,
    currentHub: { ...state.currentHub, ...payload },
    edit: false,
  });
const updateHubFailure = (state: any, { error }: any) =>
  Object.assign({}, state, {
    loading: false,
    error,
    loadingCoverImg: false,
    loadingProfileImg: false,
    edit: false,
  });

const setLoadingCoverImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingCoverImg: val });
const setLoadingProfileImg = (state: any, { val }: any) =>
  Object.assign({}, state, { loadingProfileImg: val });

const setEditStatus = (state: any, { val }: any) =>
  Object.assign({}, state, { edit: val });

const getIgLatestRequest = (state: any) =>
  Object.assign({}, state, { ...INITIAL_STATE, loadingIgBox: true });
const getIgLatestSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, {
    loadingIgBox: false,
    currentHub: { ...state.currentHub, ig_box: payload },
  });
const getIgLatestFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loadingIgBox: false, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HUBS_REQUEST]: getHubsRequest,
  [Types.GET_HUBS_SUCCESS]: getHubsSuccess,
  [Types.GET_HUBS_FAILURE]: getHubsFailure,
  [Types.GET_HUB_BY_ID_REQUEST]: getHubByIdRequest,
  [Types.GET_HUB_BY_ID_SUCCESS]: getHubByIdSuccess,
  [Types.GET_HUB_BY_ID_FAILURE]: getHubByIdFailure,
  [Types.GET_MERCHANTS_BY_HUB_REQUEST]: getMerchantsByHubRequest,
  [Types.GET_MERCHANTS_BY_HUB_SUCCESS]: getMerchantsByHubSuccess,
  [Types.GET_MERCHANTS_BY_HUB_FAILURE]: getMerchantsByHubFailure,
  [Types.GET_IG_LATEST_REQUEST]: getIgLatestRequest,
  [Types.GET_IG_LATEST_SUCCESS]: getIgLatestSuccess,
  [Types.GET_IG_LATEST_FAILURE]: getIgLatestFailure,
  [Types.UPDATE_HUB_REQUEST]: updateHubRequest,
  [Types.UPDATE_HUB_SUCCESS]: updateHubSuccess,
  [Types.UPDATE_HUB_FAILURE]: updateHubFailure,
  [Types.SET_LOADING_COVER_IMG]: setLoadingCoverImg,
  [Types.SET_LOADING_PROFILE_IMG]: setLoadingProfileImg,
  [Types.SET_EDIT_STATUS]: setEditStatus,
});
