import api from "./api";

import {
  createUser,
  createAnon,
  createUserOauth,
  updateUser,
  findOrCreateUser,
  updateUserInfo,
  getUserById,
  changePwd,
  getProdRecosById,
} from "./users";
import { localLogin, oauthLogin, localLogout, oneTapLogin } from "./auth";
import {
  getMerchants,
  getMerchantById,
  putMerchant,
  getIgByMerchantLatest,
  postMerchant,
  getMerchantsReco,
  getMerchantsRecentlyView,
  postMerchantLike,
  getMerchantsSearch,
  getMerchantsByCat,
  getMerchantsByAttr,
} from "./merchants";
import { getCalixaIg } from "./general";
import { getProductById } from "./products";
import {
  getHubs,
  updateHub,
  getHubById,
  getMerchantsByHub,
  getIgByHubLatest,
} from "./hubs";
import { searchUnsplash } from "./unsplash";
import { publishCanva } from "./canva";
import {
  getShopifyUrl,
  getShopifyProducts,
  putShopifyProducts,
} from "./shopify";
import { getTopPicks } from "./top.picks";
import { addPos } from "./pos";
import { recordEvent } from "./events";
import {
  getDiscovery,
  deleteDiscoveryData,
  getVisualProductSearch,
} from "./discovery";

const getRoot = () => api.get("");

export default {
  getRoot,
  createUser,
  createAnon,
  createUserOauth,
  updateUser,
  findOrCreateUser,
  updateUserInfo,
  getUserById,
  getMerchants,
  getMerchantsByHub,
  getMerchantById,
  putMerchant,
  postMerchant,
  getMerchantsReco,
  getMerchantsRecentlyView,
  postMerchantLike,
  getMerchantsSearch,
  getCalixaIg,
  getProductById,
  getHubs,
  getHubById,
  getIgByHubLatest,
  getIgByMerchantLatest,
  updateHub,
  getShopifyUrl,
  getShopifyProducts,
  putShopifyProducts,
  localLogin,
  oauthLogin,
  oneTapLogin,
  localLogout,
  searchUnsplash,
  publishCanva,
  changePwd,
  getTopPicks,
  addPos,
  recordEvent,
  getProdRecosById,
  getDiscovery,
  getVisualProductSearch,
  deleteDiscoveryData,
  getMerchantsByAttr,
  getMerchantsByCat,
};
