import { createReducer, createActions } from "reduxsauce";
import _ from "lodash";

const { Types, Creators }: any = createActions({
  getTopPicksRequest: null,
  getTopPicksSuccess: ["payload"],
  getTopPicksFailure: ["error"],
  // postTopPickRequest: null,
  // postTopPickSuccess: ["payload"],
  // postTopPickFailure: ["error"],
});

export const TopPicksTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
};

const getTopPicksRequest = (state: any) =>
  Object.assign({}, state, { loading: true, data: [], error: null });

const getTopPicksSuccess = (state: any, { payload }: any) =>
  Object.assign({}, state, { loading: false, data: _.shuffle(payload.data) });

const getTopPicksFailure = (state: any, { error }: any) =>
  Object.assign({}, state, { loading: false, error });

// const postTopPickRequest = (state: any) => Object.assign({}, state);

// const postTopPickSuccess = (state: any, { payload }: any) =>
//   Object.assign({}, state, { loading: false, data: payload.data });

// const postTopPickFailure = (state: any, { error }: any) =>
//   Object.assign({}, state, { loading: false, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TOP_PICKS_REQUEST]: getTopPicksRequest,
  [Types.GET_TOP_PICKS_SUCCESS]: getTopPicksSuccess,
  [Types.GET_TOP_PICKS_FAILURE]: getTopPicksFailure,
  // [Types.POST_TOP_PICKS_REQUEST]: postTopPickRequest,
  // [Types.POST_TOP_PICKS_SUCCESS]: postTopPickSuccess,
  // [Types.POST_TOP_PICKS_FAILURE]: postTopPickFailure,
});
