import api from "./api";

export const getMerchants = (query: any) => api.get("merchants", query);
export const getMerchantById = (merchant_id: string, uid: string) =>
  api.get(`merchants/${merchant_id}`, { uid }); // business page
export const putMerchant = (payload: any) => api.put(`merchants`, payload); // business page
export const postMerchant = (payload: any) => api.post("merchants", payload);
export const getIgByMerchantLatest = (merchant_id: string) =>
  api.get(`merchants/insta/${merchant_id}`);
export const getMerchantsReco = (payload: any) =>
  api.get("merchants/reco/merchant", payload);
export const getMerchantsRecentlyView = (payload: any) =>
  api.get("merchants/recentlyview", payload);
export const postMerchantLike = (payload: any) =>
  api.post("merchants/like", payload);
export const getMerchantsSearch = (query: any) => api.post("merchants", query);
export const getMerchantsByCat = (cat_id: string) =>
  api.get(`merchants/by/category/${cat_id}`);
export const getMerchantsByAttr = (brand_attr: string) =>
  api.get(`merchants/by/attributes/${brand_attr}`);
