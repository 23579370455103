import styled from "styled-components";
import {
  space,
  width,
  height,
  flexDirection,
  justifyContent,
  alignItems,
  flex,
  flexWrap,
} from "styled-system";

const Section: any = styled.div`
  space: ${space};
  ${width};
  ${height};
  ${flexDirection};
  ${justifyContent};
  ${alignItems};
  display: flex;
  ${flex};
  ${flexWrap};
  position: relative;
`;

export default Section;
