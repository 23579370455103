import api from "./api";

export const getShopifyUrl = (merchantId: any) =>
  api.get("shopify/", merchantId);

export const getShopifyProducts = (merchantId: any) =>
  api.get(`shopify/product/${merchantId}`);

export const putShopifyProducts = (merchantId: any) =>
  api.put(`shopify/product/${merchantId}`);
