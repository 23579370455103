export const getRandomKey = (l: number) => {
  return Math.random()
    .toString(36)
    .substring(2, l + 2);
};

export const elapsedHours = (dt2: Date, dt1: Date) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
};
