import { put, call } from "redux-saga/effects";
import GeneralActions from "../../redux/general.redux";

export function* getCalixaIg(api: any): Generator<any, any, any> {
  try {
    const response: any = yield call(api.getCalixaIg);
    if (response.ok) {
      const payload = response.data;

      yield put(GeneralActions.getCalixaIgSuccess(payload));
    } else {
      yield put(GeneralActions.getCalixaIgFailure(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
